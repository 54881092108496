import { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useIntl } from "react-intl";

import CustomTable from "components/table";
import {
    ActionMenu,
    CustomButton,
    ConfirmationButton,
    TableButtonGroup,
} from "components";
import { masterPaginationServices, tableInitialState, visaStatusOptions } from "utils/constants";
import { getTableParams } from "utils/global";
import { RootReducerState } from "utils/types";
import { getMasterPaginationData } from "redux/actions";
import PageLayout from "layouts/PageLayout";
import { flightColumns } from "utils/constants/tableColumn/column";
import { services } from "redux/services";
import { useCheckPermissions, useCheckRole, useToastMessage } from "hooks";
import { Delete, Edit, RemoveRedEye, Update, Verified } from "@mui/icons-material";
import VisaView from "./View";
import { CustomTab } from "components";
import { useNavigate, useParams } from "react-router-dom";
import VisaStatusForm from "./VisaStatusForm";

const permissionSet = {
    createVisa: ['candidateFlight.createVisa'],
    updateVisa: ['candidateFlight.updateVisa'],
    deleteVisa: ['candidateFlight.deleteVisa'],
    visasExports: ['candidateFlight.visaListExport']
}

const Account: React.FC = () => {
    const { formatMessage } = useIntl();
    const { slug } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { toastMessage } = useToastMessage();
    const checkPermissions = useCheckPermissions(permissionSet);

    const [visaModal, setVisaModal] = useState(false);
    const [visaCheckModal, setVisaCheckModal] = useState(false);
    const [visaViewModal, setVisaViewModal] = useState(false);
    const { isAdmin, isDocumentation, isDeparture } = useCheckRole();
    const [selectedData, setSelectedData] = useState<any>();

    const [tableState, setTableState] = useState(tableInitialState);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const { userData, loading } = useSelector(
        ({ masterPaginationReducer }: RootReducerState) => ({
            userData:
                masterPaginationReducer[masterPaginationServices.candidateFlight].data,
            loading:
                masterPaginationReducer[masterPaginationServices.candidateFlight]
                    .loading,
        }),
        shallowEqual
    );

    const { rows, count } = userData;

    useEffect(() => {
        onApiCall();
    }, [tableState, slug]);

    function onApiCall(withState: boolean = true) {
        const params = getTableParams(withState ? tableState : {});
        if (slug === 'unverified') params.is_verified = 0;
        else if (slug === 'verified') params.is_verified = 1;
        else params.is_verified = 2;

        dispatch(
            getMasterPaginationData(masterPaginationServices.candidateFlight, params)
        );
    }

    let columnAction = {
        label: formatMessage({ id: "action" }),
        name: "",
        options: {
            customBodyRender: (_: any, tableMeta: any) => {
                const rowData = rows[tableMeta.rowIndex];
                return (
                    <ActionMenu>
                        <CustomButton
                            color="warning"
                            size="small"
                            label={`View ${rowData?.is_verified == 0 ? "And Verify Visa" : ""}`}
                            variant="outlined"
                            onClick={() => handleViewClick(rowData)}
                            startIcon={<RemoveRedEye />}
                        />
                          <CustomButton
                            color="success"
                            size="small"
                            label={`Update Status`}
                            variant="outlined"
                            onClick={() => handleVisaClick(rowData)}
                            startIcon={<Update />}
                        />
                    </ActionMenu>
                );
            },
        },
    };

    function handleViewClick(rowData: any) {
        setVisaViewModal(true);
        setSelectedData(rowData);
    }

    function handleVisaClick(rowData: any) {
        setVisaCheckModal(true);
        setSelectedData(rowData);
    }

    return (
        <PageLayout title={formatMessage({ id: "flight" })} hideToolbar>
            {slug &&
                <CustomTab
                    tabs={visaStatusOptions}
                    active={slug}
                    onChange={(tab: string) => {
                        navigate(`/admin/visas/${tab}`);
                        setTableState(tableInitialState);
                    }}
                />
            }
            <CustomTable
                columns={[...flightColumns(formatMessage), columnAction]}
                tableData={rows}
                tableState={tableState}
                rowsCount={count}
                setTableState={setTableState}
                loading={loading || deleteLoading}
                options={{
                    download: checkPermissions.visasExports,
                    print: checkPermissions.visasExports,
                }}
            />

            {visaViewModal && (
                <VisaView
                    open={visaViewModal}
                    onClose={() => setVisaViewModal(false)}
                    defaultData={[selectedData]}
                    refreshData={onApiCall}
                />
            )}


            {visaCheckModal &&
                <VisaStatusForm
                    refreshData={onApiCall}
                    selectedData={selectedData}
                    open={visaCheckModal}
                    closeModal={() => setVisaCheckModal(false)}
                />
            }

        </PageLayout>
    );
};

export default Account;
