import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useParams } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import {
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { HoverLoader } from "components";
import { useCheckPermissions, useCheckRole, useCreateLookupOptions, useToastMessage } from "hooks";
import { candidateStatusEnum, masterLookupServices } from "utils/constants";
import { services } from "redux/services";
import { RootReducerState } from "utils/types";
import { getMasterLookupData } from "redux/actions";
import GeneralForm from "./GeneralForm";
import { Send } from "@mui/icons-material";
import { ConfirmationIconButton } from "components";
import DocumentUploadForm from "./DocumentUploadForm";
import ExtraInformationForm from "./ExtraInformationForm";
import { getCandiateStatus } from "utils/global";
import CompanyAppliedForm from "./CompanyAppliedForm";

const permissionSet = {
  updateExtraCandidateInformation: ['candidates.updateExtraCandidateInformation'],
  assignCompanyToCandidate: ['candidates.assignCompanyToCandidate'],
  uploadCandidateDocument: ['candidates.uploadCandidateDocument'],
}

const Form: React.FC = () => {
  const { uuid, tab }: any = useParams<any>();
  const { toastMessage } = useToastMessage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const checkPermissions = useCheckPermissions(permissionSet);

  const [loading, setLoading] = useState(false);
  const [defaultData, setDefaultData] = useState<any>(null);
  const { isAgent, isReceptionist, isAdmin } = useCheckRole();
  const [fileArray, setFileArray] = useState([]);
  const [companyData, setCompanyData] = useState([]);

  const [description, setDescription] = useState({
    professions: [],
    languages: [],
    experience_info: '',
    education_info: '',
    extra_info: '',
    profile_summary: ''
  });

  const [companyApplied, setCompanyApplied] = useState({
    company_id: null,
    vacancy_id: null,
    profession: null,
  });


  const { candidateLookup } = useSelector(
    ({ masterLookupReducer }: RootReducerState) => {
      return {
        candidateLookup:
          masterLookupReducer[masterLookupServices.getCandidateLookup].data,
      };
    },
    shallowEqual
  );

  const selectOptions = useCreateLookupOptions(candidateLookup);

  useEffect(() => {
    dispatch(getMasterLookupData(masterLookupServices.getCandidateLookup));
    fetchCompanyList();
  }, []);

  function fetchCompanyList() {
    services.getCompanyVacancy({}).then((res: any) => {
      const transformedData = res?.result?.length > 0 ? res?.result?.map((company: any) => {
        return {
          value: company?.id,
          label: company?.name,
          vacancies: company?.vacancies?.map((vacancy: any) => {
            return {
              value: vacancy?.id,
              label: vacancy?.chalan_number,
              title: vacancy?.title,
              publish_date: vacancy?.publish_date,
              working_hours: vacancy?.working_hours,
              lot_number: vacancy?.lot_number,
              contract_period: vacancy?.contract_period,
              details: vacancy?.details?.map((detail: any) => {
                return {
                  label: detail?.profession,
                  value: detail?.profession,
                  male: detail?.male,
                  female: detail?.female,
                  qualification: detail?.qualification,
                  currency: detail?.currency,
                  salary: detail?.salary,
                };
              })
            };
          })
        };
      }) : [];
      setCompanyData(transformedData)
    }).catch((err: any) => {
      toastMessage(err?.data?.message || "Something Wrong", "error");
    });
  }

  function fetchCandidateDetail() {
    setLoading(true);
    services
      .getCandidateByUuid({ uuid })
      .then((res: any) => {
        setLoading(false);
        const response = res.result;
        setDefaultData(response);
        setDescription({
          ...description,
          experience_info: response?.information?.experience_info,
          education_info: response?.information?.education_info,
          extra_info: response?.information?.extra_info,
          profile_summary: response?.information?.profile_summary
        })
        setFileArray(response?.documents);
      })
      .catch((err: any) => {
        toastMessage(err?.data?.message || "Something Wrong", "error");
      }).finally(() => setTimeout(() => {
        setLoading(false);
      }, 1000));
  }

  useEffect(() => {
    if (defaultData?.information?.professions || defaultData?.information?.languages) {
      setDescription({
        ...description,
        languages: selectOptions?.language?.length > 0 ? selectOptions?.language?.filter((item: any) => defaultData?.information?.languages?.includes(item.label)) : [],
        professions: selectOptions?.profession?.length > 0 ? selectOptions?.profession?.filter((item: any) => defaultData?.information?.professions?.includes(item.label)) : [],
      })
    }
  }, [selectOptions, defaultData]);

  //RESET DEFAULTVALUE IN UPDATE MODE
  useEffect(() => {
    if (uuid) {
      fetchCandidateDetail();
    }
  }, [uuid]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    navigate(`/admin/candidates/update/${newValue}/${uuid}`);
  };

  function handleStatus(params: any) {
    services
      .updateCandidateStatus(params)
      .then((res: any) => {
        navigate(`/admin/candidates/list/all`);
        toastMessage("Candidate status updated");
      })
      .catch((err: any) => {
        toastMessage(
          err?.data?.message || "Something wrong !",
          "error"
        );
      });
  }

  const { isReviewed, isPending, isCancelled, isRequested } = getCandiateStatus(defaultData?.status)

  return (
    <TabContext value={tab}>
      {loading && <HoverLoader />}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          paddingX: 3,
        }}
      >
        <Grid container spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              gap={1}
              mb={2}
            >
              <IconButton
                color="primary"
                sx={{ border: 1 }}
                onClick={() => navigate('/admin/candidates/list/all')}
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" color="primary">
                {" "}
                {formatMessage({ id: "update" }) +
                  " " +
                  formatMessage({ id: "candidate" })}
              </Typography>
            </Stack>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <TabList onChange={handleChange}>
              <Tab label="General" value="general" />
              {checkPermissions.updateExtraCandidateInformation && <Tab label="Extra Info" value="information" />}
              {checkPermissions.uploadCandidateDocument && <Tab label="Document" value="document" />}
              {checkPermissions.assignCompanyToCandidate && <Tab label="Company" value="company" />}
            </TabList>
          </Grid>
          {(isAgent || isReceptionist) && (isPending || isReviewed || isCancelled) && (
            <Grid item lg={2} md={2} sm={12} xs={12}>
              <ConfirmationIconButton
                buttonProps={{
                  fullWidth: true,
                  startIcon: <Send />,
                  variant: "contained",
                  color: "warning",
                }}
                onConfirm={() =>
                  handleStatus({ uuid: defaultData.uuid, status: isAgent ? candidateStatusEnum.reviewing : candidateStatusEnum.requested })
                }
                buttonLabel={`Send for ${isAgent ? "Review" : "Process"}`}
                title={`Send for ${isAgent ? "Review" : "Process"}`}
                subTitle='Please verify that all informations have been appropriately updated before  re submission.'
              />
            </Grid>
          )}

          {/* {(isAgent || isReceptionist) && isCancelled && (
            <Grid item lg={2} md={2} sm={12} xs={12}>
              <ConfirmationIconButton
                buttonProps={{
                  fullWidth: true,
                  startIcon: <Refresh />,
                  variant: "contained",
                  color: "warning",
                }}
                onConfirm={() =>
                  handleStatus({
                    uuid: defaultData.uuid,
                    status: isAgent ? candidateStatusEnum.reviewing : candidateStatusEnum.requested
                  })
                }
                buttonLabel={`Send for ${isAgent ? "Review" : "Process"}`}
                title={`Send for ${isAgent ? "Review" : "Process"}`}
                subTitle='Please verify that all informations have been appropriately updated before  re submission.'
              />
            </Grid>
          )} */}
        </Grid>
      </Box>
      <TabPanel value="general" sx={{ paddingY: 0 }}>
        {defaultData && (
          <GeneralForm
            uuid={uuid}
            formType="update"
            defaultData={defaultData}
            selectOptions={selectOptions}
            onRefresh={fetchCandidateDetail}
          />
        )}
      </TabPanel>
      {checkPermissions.updateExtraCandidateInformation &&
        <TabPanel value="information" sx={{ paddingY: 0 }}>
          <ExtraInformationForm
            uuid={uuid}
            description={description}
            setDescription={setDescription}
            selectOptions={selectOptions}
            informationData={defaultData?.information}
          />
        </TabPanel>
      }
      {checkPermissions.uploadCandidateDocument &&
        <TabPanel value="document" sx={{ paddingY: 0 }}>
          <DocumentUploadForm
            uuid={uuid}
            name="certificate"
            fileArray={fileArray}
            setFileArray={setFileArray}
          />
          <DocumentUploadForm
            uuid={uuid}
            name="experience"
            fileArray={fileArray}
            setFileArray={setFileArray}
          />
          {(isReceptionist || isAdmin) && (
            <DocumentUploadForm
              uuid={uuid}
              name="medical"
              fileArray={fileArray}
              setFileArray={setFileArray}
            />
          )}
        </TabPanel>
      }
      {checkPermissions.assignCompanyToCandidate &&
        <TabPanel value="company" sx={{ paddingY: 0 }}>
          <CompanyAppliedForm
            uuid={uuid}
            companyApplied={companyApplied}
            setCompanyApplied={setCompanyApplied}
            companyData={companyData}
            refreshCompanyApi={fetchCompanyList}
            vacancyData={defaultData?.vacancy}
          />
        </TabPanel>
      }
    </TabContext>
  );
};

export default Form;
