import {
  BookOnline,
  Cancel,
  Check,
  DisabledByDefault,
  DocumentScanner,
  Feed,
  Info,
  Input,
  ListAlt,
  MoneyOff,
  NotInterested,
  Pending,
  Person,
  Report,
  Reviews,
  SettingsAccessibility,
  Verified,
  Work,
} from "@mui/icons-material";
import {
  DefaultReducerListType,
  DefaultReducerObjectType,
  TableFilterStateType,
  PaginationReducerType,
  AuthReducerObjectType,
  RecentRowReducerType,
} from "utils/types";

export const roleData = {
  superadmin: "superadmin",
  admin: "admin",
  accountant: "accountant",
  agent: "agent",
  receptionist: "receptionist",
  documentation: "documentation",
  departure: "departure",
};

export const genderTypeOptions = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
  { label: "Both", value: 3 },
];

export const constants = {
  df: "YYYY-MM-DD",
  dtf: "YYYY-MM-DD hh:mm A",
  tfAmPM: "hh:mm A",
  tsf: "hh:mm:ss",
  t24hf: "HH:mm",
  t24hsf: "HH:mm:ss",
};

export const callType = [
  { label: "Enquiry", value: "1" },
  { label: "Appointment", value: "2" },
];

export const tableInitialState: TableFilterStateType = {
  pageSize: 20,
  page: 1,
  sortKey: "",
  sortType: "",
  columnFilter: [],
  searchColumnFilters: [],
};

export const filterTypes = {
  text: "STRING",
  number: "NUMBER",
  date: "DATE",
  select: "SELECT",
  multiSelect: "MULTI-SELECT",
  boolean: "BOOLEAN",
  custom: "CUSTOM",
};

export const filterOperators = {
  asc: 0,
  desc: 1,
  isLessThan: 0,
  isLessThanOrEqualTo: 1,
  isEqualTo: 2,
  isNotEqualTo: 3,
  isGreaterThanOrEqualTo: 4,
  isGreaterThan: 5,

  startsWith: 6,
  endsWith: 7,
  contains: 8,
  isContainedIn: 9,
  doesNotContain: 10,
};

export const numberFiltersLists = [
  { label: "Is Less Than", operator: filterOperators.isLessThan },
  {
    label: "Is Less Than Or Equal To",
    operator: filterOperators.isLessThanOrEqualTo,
  },
  { label: "Is Equal To", operator: filterOperators.isEqualTo },
  { label: "Is Not Equal To", operator: filterOperators.isNotEqualTo },
  {
    label: "Is Greater Than Or Equal To",
    operator: filterOperators.isGreaterThanOrEqualTo,
  },
  { label: "Is Greater Than", operator: filterOperators.isGreaterThan },
];

export const dateFiltersLists = numberFiltersLists;

export const textFiltersLists = [
  { label: "Starts With", operator: filterOperators.startsWith },
  { label: "Ends With", operator: filterOperators.endsWith },
  { label: "Contains", operator: filterOperators.contains },
  { label: "Is Contained In", operator: filterOperators.isContainedIn },
  { label: "Does Not Contain", operator: filterOperators.doesNotContain },
];

export const defaultArrayState: DefaultReducerListType = {
  loading: false,
  error: false,
  data: [],
};

export const defaultObjectState: DefaultReducerObjectType = {
  loading: false,
  error: false,
  data: {},
};

export const AuthObjectState: AuthReducerObjectType = {
  loading: false,
  error: false,
  data: {},
  token: ''
};

export const defaultPaginationState: PaginationReducerType = {
  loading: false,
  error: false,
  data: {
    modelItems: [],
    totalRecord: 0,
  },
};

export const defaultRecentRowState: RecentRowReducerType = {
  loading: false,
  error: false,
  data: {
    recentModelItems: [],
  },
};

export const genderList = [
  { label: "Male", value: 'male', code: "M" },
  { label: "Female", value: 'female', code: "F" },
  { label: "Other", value: 'other', code: "B" },
];

export const booleanTypes = {
  Yes: true,
  No: false,
};

export const blacklistOptions = [
  { label: "Blacklist", value: 1 },
  { label: "Clean", value: 0 },
];

export const passportOnlyOptions = [
  { label: "Passport with company", value: 1 },
  { label: "Passport with agent|candidate", value: 0 },
];


export const yesNoOptions = [
  { label: "Yes", value: 1 },
  { label: "No", value: 0 },
];

export const booleanOptions = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const statusOptions = [
  { label: "Inactive", value: "0", color: "#FF0000" },
  { label: "Active", value: "1", color: "#008000" },
];

export const candidateStatusEnum = {
  pending: "pending",
  requested: 'requested',
  // reRequested: "re-requested",
  cancelled: "cancelled",
  approved: "approved",
  reviewing: "reviewing",
  reviewed: "reviewed",
};

export const candidateStatusOptions = [
  {
    label: "All",
    value: "all",
    color: "#616161",
    roles: [roleData.admin],
    sort: 0,
    icon: <ListAlt />,
  },
  {
    label: "Initial",
    value: "pending",
    color: "#6327e4",
    roles: [roleData.admin, roleData.agent],
    sort: 2,
    icon: <Pending />,
  },
  {
    label: "In Review",
    value: "reviewing",
    color: "#ffc107",
    roles: [roleData.admin, roleData.agent, roleData.receptionist],
    sort: 3,
    icon: <Reviews />,
  },
  {
    label: "Reviewed",
    value: "reviewed",
    color: "#00bcd4",
    roles: [roleData.admin, roleData.agent, roleData.receptionist, roleData.documentation],
    sort: 4,
    icon: <Check />,
  },
  {
    label: "Requested",
    value: "requested",
    color: "#d99800",
    roles: [roleData.admin, roleData.receptionist, roleData.documentation],
    sort: 5,
    icon: <Input />,
  },
  // {
  //   label: "Re-Requested",
  //   value: "re-requested",
  //   color: "#ffc107",
  //   roles: [roleData.admin, roleData.receptionist, roleData.documentation],
  //   sort: 6,
  //   icon: <Refresh />,
  // },
  {
    label: "Cancelled",
    value: "cancelled",
    color: "#FF0000",
    roles: [roleData.admin, roleData.agent, roleData.receptionist, roleData.documentation],
    sort: 7,
    icon: <Cancel />,
  },
  {
    label: "Approved",
    value: "approved",
    color: "#008000",
    roles: [roleData.admin, roleData.documentation, roleData.departure],
    sort: 1,
    icon: <Verified />,
  },
];

export const visaStatusOptions = [
  { id: 2, label: "All", value: "all", color: "primary", icon: <ListAlt /> },
  {
    id: 0,
    label: "Unverified",
    value: "unverified",
    color: "error",
    icon: <NotInterested />,
  },
  {
    id: 1,
    label: "Verified",
    value: "verified",
    color: "success",
    icon: <Verified />,
  },
];


export const advanceSearchTab = [
  {
    id: 0,
    label: "Candidate",
    value: "candidate",
    color: "secondary",
    icon: <Person />,
  },
  {
    id: 1,
    label: "Company",
    value: "company",
    color: "success",
    icon: <Work />,
  },
];

export const profileTab = [
  {
    id: 0,
    label: "General",
    value: "general",
    color: "secondary",
    icon: <Info />,
  },
  {
    id: 1,
    label: "Documents",
    value: "documents",
    color: "success",
    icon: <DocumentScanner />,
  },
];

export const accountingTab = [
  {
    id: 0,
    label: "Candidate Fee",
    value: "candidate",
    color: "secondary",
    icon: <Feed />,
  },
  {
    id: 1,
    label: "Expense",
    value: "expense",
    color: "success",
    icon: <MoneyOff />,
  },
  // {
  //   id: 2,
  //   label: "Income",
  //   value: "income",
  //   color: "success",
  //   icon: <Money />,
  // },
  {
    id: 3,
    label: "Report",
    value: "report",
    color: "success",
    icon: <Report />,
  },
];


export const subscriptionTab = [
  {
    id: 0,
    label: "Active",
    value: "active",
    color: "secondary",
    icon: <BookOnline />,
  },
  {
    id: 1,
    label: "Cancelled",
    value: "cancel",
    color: "error",
    icon: <Cancel />,
  },
  {
    id: 2,
    label: "Expired",
    value: "expire",
    color: "warning",
    icon: <DisabledByDefault />,
  },
];

export const planPeriodTypeOption = [
  { label: "Year", value: 'year' },
  { label: "Month", value: 'month' },
  { label: "Day", value: 'day' },
];


export const reportTab = [
  {
    id: 0,
    label: "Candidate",
    value: "candidate",
    color: "secondary",
    icon: <Person />,
  },
  // {
  //   id: 1,
  //   label: "Company",
  //   value: "company",
  //   color: "success",
  //   icon: <Work />,
  // },
  {
    id: 2,
    label: "Visa",
    value: "visa",
    color: "info",
    icon: <Report />,
  },
];


export const usersTab = [
  {
    id: 0,
    label: "User",
    value: "users",
    color: "secondary",
    icon: <Person />,
  },
  {
    id: 2,
    label: "Role",
    value: "roles",
    color: "info",
    icon: <SettingsAccessibility />,
  },
];

export const accountStatusOptions = [
  {
    label: "Requested",
    value: "requested",
    color: "#d99800",
    roles: [roleData.admin, roleData.receptionist, roleData.documentation],
    sort: 5,
    icon: <Input />,
  },
  {
    label: "Cancelled",
    value: "cancelled",
    color: "#FF0000",
    roles: [roleData.admin, roleData.agent, roleData.receptionist, roleData.documentation],
    sort: 7,
    icon: <Cancel />,
  },
  {
    label: "Verified",
    value: "verified",
    color: "#008000",
    roles: [roleData.admin, roleData.documentation, roleData.departure],
    sort: 1,
    icon: <Verified />,
  },
];

export const visaFlightStatusOptions = [
  {
    label: "PENDING",
    value: "PENDING",
    color: "#ff9800",
  },
  {
    label: "EXPIRED",
    value: "EXPIRED",
    color: "#ff5722",
  },
  {
    label: "REJECTED",
    value: "REJECTED",
    color: "#b2102f",
  },
  {
    label: "ACTIVE",
    value: "ACTIVE",
    color: "#2196f3",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
    color: "#ff1744",
  },
  {
    label: "APPROVED",
    value: "APPROVED",
    color: "#4caf50",
  },
];