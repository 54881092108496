import { services } from "redux/services";

const check = <T extends string>(
  collection: Record<T, keyof typeof services>
) => collection;

export const masterPaginationServices = check({
  account: "getAllAccount",
  candidate: "getAllCandidate",
  company: "getAllCompany",
  role: "getAllRole",
  profession: "getAllProfession",
  permission: "getAllPermission",
  spokenLanguage: "getAllSpokenLanguage",
  subscriptionFeature: "getAllSubscriptionFeature",
  paymentMethod: "getAllPaymentMethod",
  user: "getAllUser",
  expense: "getAllExpense",
  vacancy: "getAllVacancy",
  miscellaneous: "getAllMiscellaneous",
  subscription: "getAllSubscription",
  candidateVisas: "getAllCandidateVisa",
  subscriptionPlan: "getAllSubscriptionPlan",
  advanceSearchCandidate:"advanceSearchCandidate",
  advanceSearchCandidateVisa:"advanceSearchCandidateVisa",
  candidateFlight: "getAllCandidateFlight",
});
