import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import { Fragment, useEffect, useRef, useState } from 'react';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { Box, Grid } from '@mui/material';
import { CustomDialog } from 'components';
import EsewaPaymentForm from './EsewaPaymentForm';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function PaymentModal({ open, handleClose }: any) {
    const esewaButtonRef: any = useRef(null);
    const { toastMessage } = useToastMessage();
    const { formatMessage } = useIntl();
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [selectedMethod, setSelectedMethod] = useState<any>(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [billingData, setBillingData] = useState({
        totalAmount: 0,
        billingId: '',
        paymentCode: '',
        khaltiUrl: ''
    });

    useEffect(() => {
        services.getSubscriptionPaymentMethod()
            .then((res: any) => {
                setPaymentMethod(res.result);
            })
            .catch((err) => {
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            });
    }, [])

    const handleSubmit = () => {
        services.saveBilling({ payment_method_id: selectedMethod?.id })
            .then((res: any) => {
                setOpenDialog(false);
                const result = res.result;
                setBillingData({
                    totalAmount: result.total_amount,
                    billingId: result.billing_id,
                    paymentCode: result.payment_code,
                    khaltiUrl: result?.payment_url
                })
                // toastMessage("Congratulations! You have successfully subscribed.");
            })
            .catch((err) => {
                setOpenDialog(false);
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            });
    }

    useEffect(() => {
        if (billingData.billingId && billingData.paymentCode && billingData.totalAmount) {
            if (billingData.paymentCode === 'khalti' && billingData.khaltiUrl) {
                window.open(billingData.khaltiUrl);
                handleClose();
                return;
            } else if (billingData.paymentCode === 'esewa') {
                handleClose();
                esewaButtonRef?.current?.click();
                return;
            } else if (billingData.paymentCode === 'connect-ips') {
                handleClose();
                toastMessage("Connect Ips is currently unavailable. Please choose other payment.", 'warning');
                return;
            }
            toastMessage("Payment is currently unavailable. Please choose other payment.", 'error');
        }
    }, [billingData.billingId, billingData.paymentCode, billingData.totalAmount])

    return (
        <Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Payment Method
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Grid container spacing={2}>
                        {paymentMethod?.map((pay: any, ind: number) => (
                            <Grid item key={ind} xs={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: 2,
                                        border: 1,
                                        borderRadius: 2,
                                        cursor: 'pointer',
                                        background: selectedMethod?.id === pay?.id ? '#f0f0f0' : 'white',
                                        '&:hover': { background: '#f9f9f9' },
                                    }}
                                    onClick={() => setSelectedMethod(pay)}
                                >
                                    {/* Image Icon */}
                                    <Box sx={{ marginRight: 2 }}>
                                        <img
                                            src={`/assets/payment/${pay.code}.png`} // Assuming `pay.image` holds the image URL
                                            alt={pay.name}
                                            style={{ width: 40, height: 40, objectFit: 'contain' }}
                                        />
                                    </Box>

                                    {/* Payment Method Name */}
                                    <Typography variant="h6">{pay.name}</Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant='contained' color='info' onClick={() => setOpenDialog(true)}>
                        Pay
                    </Button>
                </DialogActions>
                <EsewaPaymentForm
                    totalAmount={billingData.totalAmount}
                    pid={billingData.billingId}
                    ref={esewaButtonRef}
                    callbackUrlOnSuccess={`${window.location.origin}/admin/payment-complete`}
                    callbackUrlOnFailure={`${window.location.origin}/admin/payment-failure`}
                />
            </BootstrapDialog>
            {openDialog && (
                <CustomDialog
                    open={openDialog}
                    onDisagree={() => setOpenDialog(false)}
                    onAgree={handleSubmit}
                    title="Are you sure you want to pay?"
                    subTitle="After purchase, plan changes are not permitted until the subscription expires."
                />
            )}
        </Fragment>
    );
}