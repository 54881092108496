import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
// material
import { Box, Grid } from "@mui/material";
import {
    CustomSelect,
} from "components/forms";
import { CustomButton, CustomModal, HoverLoader } from "components";
import { useToastMessage } from "hooks";
import { services } from "redux/services";
import { visaFlightStatusOptions } from "utils/constants";
import { Close, Save } from "@mui/icons-material";

interface Props {
    open: boolean;
    closeModal: () => void;
    refreshData: () => void;
    selectedData: any;
}

const VisaStatusForm = (props: Props) => {
    const { closeModal, open, refreshData, selectedData } = props;
    const { toastMessage } = useToastMessage();
    const {
        handleSubmit,
        formState: { errors },
        control,
        reset,
    } = useForm<any>({
        mode: "all",
        defaultValues: {
            has_original_passport: false,
            passport_recieved_date: new Date(),
        },
    });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);

    function onSubmit(data: any) {
        setLoading(true);
        data.id = selectedData.id;
        data.visa_status = data.visa_status.value;
        const apiFunc = "updateCandidateFlight";
        const message = "updated-message";
        services[apiFunc](data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: message }, { title: "visa" }));
                closeModal();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(
                    err?.data?.message || "Something wrong !",
                    "error"
                );
            });
    }

    useEffect(() => {
        if (selectedData) {
            reset({
                visa_status: visaFlightStatusOptions?.find((item: any) => item.value == selectedData.visa_status),
            });
        }
    }, [selectedData]);

    return (
        <CustomModal
            open={open}
            onClose={closeModal}
            title={"Visa Status"}
        >
            <Box sx={{ width: 500, padding: 3 }}>
                <Grid container spacing={2}>
                    {loading && <HoverLoader />}
                    <Grid item xs={12}>
                        <CustomSelect
                            options={visaFlightStatusOptions}
                            label={formatMessage({ id: "visa-status" })}
                            name="visa_status"
                            control={control}
                            error={errors?.visa_status}
                            rules={{ required: true }}
                        />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent='end' gap={2}>
                        <CustomButton
                            startIcon={< Close />}
                            label='Close'
                            color="error"
                            onClick={closeModal}
                        />
                        <CustomButton
                            startIcon={<Save />}
                            label='Update'
                            color="secondary"
                            onClick={handleSubmit(onSubmit)}
                        />
                    </Grid>
                </Grid>
            </Box>
        </CustomModal>
    );
};

export default VisaStatusForm;
