
import { Update } from '@mui/icons-material';
import { Box, Button, Chip, Drawer, Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { ConfirmationButton } from 'components';
import { CustomCheckBox, CustomTextArea } from 'components/forms';
import { useCheckPermissions, useCheckRole, useToastMessage } from 'hooks';
import {  useEffect, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { services } from 'redux/services';

interface Props {
    open: boolean;
    onClose: () => void;
    defaultData?: any;
    refreshData?: any;
}

function renderBooleanText(txt: any) {
    if (txt == 1) return <Chip label='Yes' color="success" variant='filled' size='small' />;
    else return <Box display='flex' gap={2}><Chip label='No' color="error" variant='filled' size='small' /><Typography color="orangered" variant='body2'>Need to confirm !</Typography></Box>;
}

const VisaView = ({ onClose, open, defaultData, refreshData }: Props) => {
    const visaData = defaultData?.[0];
    const { handleSubmit, control, watch, reset } = useForm({ mode: 'all' });
    const { formatMessage } = useIntl();
    const { toastMessage } = useToastMessage();
    const checkPermissions = useCheckPermissions({ candidateVisaVerify: ['candidateVisas.verifyVisa'] });

    function onSubmit(data: any) {
        if (visaData?.has_departure_confirm == 0) {
            toastMessage('Departure confirmation is required.', 'error');
            return;
        }
        if (visaData?.has_labour_clearance == 0) {
            toastMessage('Labour clearance is required.', 'error');
            return;
        }
        if (visaData?.has_ticket_confirm == 0) {
            toastMessage('Ticket confirmation  is required.', 'error');
            return;
        }
        data.id = visaData?.id;
        data.is_verified = data?.is_verified ? 1 : 0;
        services.verifyCandidateVisa(data).then((res: any) => {
            toastMessage(formatMessage({ id: 'verified-message' }, { title: "visa" }));
            onClose();
            refreshData();
        }).catch((err: any) => {
            toastMessage(err?.data?.message || "Something wrong !", 'error');
        })
    }

    useEffect(() => {
        if (visaData) {
            reset({
                id: visaData.id,
                is_verified: visaData.is_verified,
                remarks: visaData.remarks,
            });
        }
    }, [visaData])


    return (
        <Drawer
            anchor={'right'}
            open={open}
            onClose={onClose}
            transitionDuration={600}
            PaperProps={{ elevation: 9 }}
            SlideProps={{ unmountOnExit: true }}
        >
            <TableContainer sx={{ marginY: 2, minWidth: 400, maxWidth: 800 }}>
                <Table size='small'>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Full Name</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{visaData?.full_name}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Nationality Code</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{visaData?.nationality}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Visa Number</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{visaData?.visa_number}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Passport Number</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{visaData?.passport_number}</Typography></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Profession</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{visaData?.profession}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Visa Type</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{visaData?.visa_type}</Typography></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Date of Birth</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{visaData?.dob}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Application Number</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{visaData?.application_number}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Date of Issue</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{visaData?.date_of_issue || 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Visa Validity</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{visaData?.visa_validity || 'N/A'}</Typography></TableCell>
                        </TableRow>
                        <TableRow
                        // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Purpose of the visa</Typography></TableCell>
                            <TableCell><Typography variant='body2' color='gray'>{visaData?.purpose_of_visa || 'N/A'}</Typography></TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Departure Confirm</Typography></TableCell>
                            <TableCell>{renderBooleanText(visaData?.has_departure_confirm)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Labour Confirm</Typography></TableCell>
                            <TableCell>{renderBooleanText(visaData?.has_labour_clearance)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell component="th" scope="row"><Typography variant='subtitle2'>Ticket Confirm</Typography></TableCell>
                            <TableCell>
                                {renderBooleanText(visaData?.has_ticket_confirm)}
                                {visaData?.has_ticket_confirm == 1 &&
                                    <Button
                                        onClick={() => window.open(visaData?.ticket_image, "_blank")}
                                    >
                                        Show Ticket
                                    </Button>
                                }
                            </TableCell>
                        </TableRow>
                        {visaData?.is_verified == 1 && <Fragment>
                            <TableRow>
                                <TableCell component="th" scope="row"><Typography variant='subtitle2'>Verified</Typography></TableCell>
                                <TableCell>{renderBooleanText(visaData?.is_verified)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" scope="row"><Typography variant='subtitle2'>Remarks</Typography></TableCell>
                                <TableCell><Typography variant='body2' color='gray'>{visaData?.remarks || 'N/A'}</Typography></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" colSpan={2}>
                                    <a href={visaData?.file} target='_blank'>
                                        <img src={visaData?.file} style={{ width: '100%', height: 200, objectFit: 'contain' }} alt="visa-image" />
                                    </a>
                                </TableCell>
                            </TableRow>
                        </Fragment>
                        }
                        {visaData?.is_verified == 0 && (checkPermissions.candidateVisaVerify) &&
                            <Fragment>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <CustomCheckBox
                                            name="is_verified"
                                            label={"Are you sure that all the information has been verified?"}
                                            control={control}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <CustomTextArea
                                            name="remarks"
                                            control={control}
                                            minRows={4}
                                            placeholder={formatMessage({ id: "remarks" })}
                                        />
                                    </TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <ConfirmationButton
                                            disabled={watch('is_verified') == 1 ? false : true}
                                            startIcon={<Update />}
                                            color='info'
                                            fullWidth
                                            variant='contained'
                                            onConfirm={handleSubmit(onSubmit)}
                                            label="Verify Visa Information"
                                            title="Are you sure you want to proceed with verifying the candidate's visa?"
                                            subTitle='Please ensure that all information have been correctly updated before proceeding with verification. Once verified, editing may not be possible.'
                                        />
                                    </TableCell>
                                </TableRow>
                            </Fragment>}
                    </TableBody>
                </Table>
            </TableContainer>
        </Drawer>
    )
}

export default VisaView;