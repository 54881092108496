import * as React from 'react';
import { REACT_APP_ESEWA_MERCHANT_ID, REACT_APP_ESEWA_VERIFY_URL } from 'utils/constants';

interface Props {
  pid: string;
  totalAmount: number;
  callbackUrlOnSuccess: string;
  callbackUrlOnFailure: string;
}

const EsewaPaymentForm = React.forwardRef<HTMLButtonElement, Props & React.ButtonHTMLAttributes<HTMLButtonElement>>((props, ref): JSX.Element => {
  const { totalAmount, callbackUrlOnSuccess, callbackUrlOnFailure, pid } = props;

  return (
    <form name="esewa" action={REACT_APP_ESEWA_VERIFY_URL} target="_blank" method="POST" >
      <input value={0} name="tAmt" type="hidden" />
      <input value={1000} name="amt" type="hidden" />
      <input value="0" name="txAmt" type="hidden" />
      <input value="0" name="psc" type="hidden" />
      <input value="0" name="pdc" type="hidden" />
      <input value={REACT_APP_ESEWA_MERCHANT_ID} name="scd" type="hidden" />
      <input value={pid} name="pid" type="hidden" />
      <input value={callbackUrlOnSuccess} type="hidden" name="su" />
      <input value={callbackUrlOnFailure} type="hidden" name="fu" />
      <button type="submit" className="btn" style={{ display: 'none' }} ref={ref}>
      </button>
    </form>
  );
});

export default EsewaPaymentForm;
