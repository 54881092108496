import { pdfjs } from "react-pdf";

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     "pdfjs-dist/build/pdf.worker.min.mjs",
//     import.meta.url
//   ).toString();
  
pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.8.69/pdf.worker.mjs";

export const convertPdfToImage = async (pdfFile: File) => {
    const fileType = pdfFile.type;
    const baseName = pdfFile?.name?.substring(0, pdfFile?.name.lastIndexOf('.'));
    if (fileType === "application/pdf") {
        // Handle PDF: Convert the first page to an image
        const canvas = document.createElement("canvas");
        const pdf = await pdfjs.getDocument(URL.createObjectURL(pdfFile)).promise;
        const page = await pdf.getPage(1); // Render the first page of the PDF
        const scale = 1.5; // Reduced scale for faster rendering
        const viewport = page.getViewport({ scale });

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        const context = canvas.getContext("2d");
        if (context) {
            await page.render({ canvasContext: context, viewport }).promise;

            // Apply brightness and contrast first
            context.filter = 'brightness(1.1) contrast(1.3)';
            context.drawImage(canvas, 0, 0);

            // Get the canvas image data for manipulation (sharpness)
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

            // Apply sharpness filter (sharpening matrix)
            const sharpenMatrix = [
                0, -1,  0,
                -1,  5, -1,
                0, -1,  0
            ];
            const sharpenedData = applyConvolution(imageData, sharpenMatrix, 1);

            // Put the sharpened image back on the canvas
            context.putImageData(sharpenedData, 0, 0);

            // Convert the canvas to a Blob (image file)
            const imageBlob = await new Promise<Blob>((resolve) =>
                canvas.toBlob((blob) => resolve(blob!), "image/png")
            );

            // Return the image as a File
            return new File([imageBlob], `${baseName}s-conv.png`, { type: "image/png" });
        }
    } else if (fileType.startsWith("image/")) {
        // If it's an image, return the file as is
        return pdfFile;
    }

    throw new Error("Unsupported file type. Please upload an image or PDF.");
};

/**
 * Apply a convolution filter to image data.
 * @param imageData The input ImageData object.
 * @param kernel The convolution kernel (3x3 matrix).
 * @param divisor Divisor to normalize the filter values.
 * @returns The processed ImageData object.
 */
const applyConvolution = (imageData: ImageData, kernel: number[], divisor: number): ImageData => {
    const { data, width, height } = imageData;
    const output = new ImageData(width, height);
    const outputData = output.data;

    // Iterate over each pixel
    for (let y = 1; y < height - 1; y++) {
        for (let x = 1; x < width - 1; x++) {
            const idx = (y * width + x) * 4; // Current pixel index
            let r = 0, g = 0, b = 0;

            // Apply the kernel matrix
            for (let ky = -1; ky <= 1; ky++) {
                for (let kx = -1; kx <= 1; kx++) {
                    const kernelIdx = (ky + 1) * 3 + (kx + 1); // Kernel index
                    const pixelIdx = ((y + ky) * width + (x + kx)) * 4; // Neighbor pixel index
                    const weight = kernel[kernelIdx];

                    r += data[pixelIdx] * weight;
                    g += data[pixelIdx + 1] * weight;
                    b += data[pixelIdx + 2] * weight;
                }
            }

            // Normalize the values and set them in the output
            outputData[idx] = Math.min(255, Math.max(0, r / divisor));
            outputData[idx + 1] = Math.min(255, Math.max(0, g / divisor));
            outputData[idx + 2] = Math.min(255, Math.max(0, b / divisor));
            outputData[idx + 3] = data[idx + 3]; // Preserve alpha
        }
    }

    return output;
};

  
  
  