import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Alert, AlertTitle, Button, Stack } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useQuery, useToastMessage } from 'hooks';
import { services } from 'redux/services';
import { useDispatch } from 'react-redux';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function PaymentComplete() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQuery();
  const { toastMessage } = useToastMessage();
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (query) {
      const queryParams: any = {};
      query.forEach((value, key) => {
        queryParams[key] = value;
      });

      if (queryParams?.pidx) {
        if (queryParams?.status === 'Completed') {
          services.completePayment({ payment_remarks: queryParams })
            .then((res: any) => {
              setMessage(res.message);
              toastMessage("Congratulations! You have successfully subscribed.");
              window.location.reload();
            })
            .catch((err) => {
              setMessage(err?.data?.message);
            });
          return;
        } else {
          navigate('/admin/payment-failure')
        }
      }

      if(queryParams?.oid && queryParams?.refId){
        services.completePayment({ payment_remarks: queryParams })
            .then((res: any) => {
              // dispatch({ type: AUTH_SUBSCRIPTION_STATUS, payload: { has_subscribed: 1 } });
              setMessage(res.message);
              toastMessage("Congratulations! You have successfully subscribed.");
              window.location.reload();
            })
            .catch((err) => {
              setMessage(err?.data?.message);
            });
          return;
      }

      toastMessage('Payment failed. Please try again!')
    }
  }, [])

  return (
    <ContentStyle sx={{ textAlign: 'center', alignItems: 'center', mt: 4 }}>
      <Stack spacing={2} sx={{ maxWidth: 600, margin: '0 auto' }}>
        <Alert
          severity="success"
          icon={<CheckCircle fontSize="large" />}
          sx={{
            alignItems: 'center',
            textAlign: 'left',
            '& .MuiAlert-icon': { alignSelf: 'flex-start' },
            borderRadius: 2,
            p: 3,
          }}
        >
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            Payment Completed
          </AlertTitle>
          Thank you! Your payment has been successfully processed. <br />
          We’ve sent a confirmation email with the details of your transaction. If you have any questions or need assistance, feel free to contact our support team.
        </Alert>

        <Button
          startIcon={<CheckCircle />}
          sx={{ marginTop: 2 }}
          size="large"
          variant="contained"
          onClick={() => navigate('/admin/subscription')}
        >
          Go Back Home
        </Button>
      </Stack>
    </ContentStyle>
  );
}
