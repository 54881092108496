import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Alert, AlertTitle, Button, Stack, Typography } from '@mui/material';
import { ErrorOutline, Link } from '@mui/icons-material';

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

export default function PaymentFailure() {
  const navigate = useNavigate();

  return (
    <ContentStyle sx={{ textAlign: 'center', alignItems: 'center', mt: 4 }}>
      <Stack spacing={2} sx={{ maxWidth: 600, margin: '0 auto' }}>
        <Alert
          severity="error"
          icon={<ErrorOutline fontSize="large" />}
          sx={{
            alignItems: 'center',
            textAlign: 'left',
            '& .MuiAlert-icon': { alignSelf: 'flex-start' },
            borderRadius: 2,
            p: 3,
          }}
        >
          <AlertTitle sx={{ fontSize: 18, fontWeight: 'bold' }}>
            Payment Failed
          </AlertTitle>
          We couldn't process your payment. This may be due to a technical issue, insufficient funds, or cancellation. <br />
          Please try again or contact your payment provider for assistance. If you have any questions, feel free to reach out to our support team.
        </Alert>

        <Button
          startIcon={<ErrorOutline />}
          sx={{ marginTop: 2 }}
          size="large"
          variant="contained"
          color="error"
          onClick={() => navigate('/admin/subscription')}
        >
          Retry Payment
        </Button>
      </Stack>
    </ContentStyle>
  );
}
