import axios, { AxiosPromise } from "axios";
import { API_ENDPOINTS } from "redux/apiEndPoints";
import { AxiosService } from "utils/axios";

interface ParamsState {
  [key: string]: any
}

export const appServices = {
  logoutUser: (params: ParamsState): AxiosPromise => axios.get(API_ENDPOINTS.LOGOUT, { params }),
  changePassword: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CHANGE_PASSWORD, body),
  dashboard: () => new AxiosService().get(API_ENDPOINTS.DASHBOARD),
  ocr: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.OCR, body),

  createAccount: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_ACCOUNT, body),
  updateAccount: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_ACCOUNT, body),
  getAccountByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_ACCOUNT_BY_ID, body),
  getAllAccount: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_ACCOUNT, body),
  deleteAccount: (body: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_ACCOUNT, body),
  updateAccountByUuid: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_ACCOUNT + '/' + body.uuid, body),
  verifyAccount: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.VERIFY_ACCOUNT, body),
  updateAccountStatus: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.UPDATE_ACCOUNT_STATUS, body),
  saveAccountFile: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.SAVE_ACCOUNT_FILE, body),
  deleteAccountFile: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_ACCOUNT_FILE, params),

  createCandidate: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_CANDIDATE, body),
  updateCandidate: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_CANDIDATE, body),
  getAllCandidate: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_CANDIDATE, body),
  getCandidateByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_CANDIDATE_BY_UUID, body),
  deleteCandidate: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_CANDIDATE, params),
  getCandidateLookup: () => new AxiosService().get(API_ENDPOINTS.CANDIDATE_LOOKUP),
  updateCandidateFlag: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_CANDIDATE_FLAG, body),
  checkBlacklistCandidate: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.CANDIDATE_CHECK_BLACKLIST, body),
  saveCandidateFile: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.SAVE_CANDIDATE_FILE, body),
  deleteCandidateFile: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_CANDIDATE_FILE, params),
  updateCandidateStatus: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_CANDIDATE_STATUS, body),
  updateCandidateInformation: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_CANDIDATE_INFORMATION, body),
  exportCandidateResume: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.EXPORT_CANDIDATE_RESUME + '/' + body.uuid, body),
  updateCandidatePassportRecieved: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_CANDIDATE_PASSPORT_RECIEVED, body),
  updateCandidateVacancy: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.ASSIGN_CANDIDATE_COMPANY, body),
  validateCandidate: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.VALIDATE_CANDIDATE, body),
  advanceSearchCandidate: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CANDIDATE_ADVANCE_SEARCH, body),
  exportAdvanceSearchCandidate: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.EXPORT_CANDIDATE_ADVANCE_SEARCH, body),

  createCandidateVisa: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_CANDIDATE_VISA, body),
  getCandidateVisaByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_CANDIDATE_VISA_BY_UUID, body),
  updateCandidateVisa: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_CANDIDATE_VISA, body),
  getAllCandidateVisa: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_CANDIDATE_VISA, body),
  deleteCandidateVisa: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_CANDIDATE_VISA, params),
  updateCandidateVisaInfo: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_CANDIDATE_VISA_INFO, body),
  verifyCandidateVisa: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.VERIFY_CANDIDATE_VISA, body),
  advanceSearchCandidateVisa: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CANDIDATE_VISA_ADVANCE_SEARCH, body),
  exportAdvanceSearchCandidateVisa: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.EXPORT_CANDIDATE_VISA_ADVANCE_SEARCH, body),

  getAllCandidateFlight: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_CANDIDATE_FLIGHT, body),
  updateCandidateFlight: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_STATUS_CANDIDATE_FLIGHT, body),

  createCompany: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_COMPANY, body),
  updateCompany: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_COMPANY, body),
  getAllCompany: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_COMPANY, body),
  getCompanyByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_COMPANY_BY_UUID, body),
  deleteCompany: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_COMPANY, params),
  searchCompany: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.COMPANY_SEARCH, body),
  getCompanyVacancy: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_COMPANY_WITH_VACANCY, body),

  createVacancy: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_VACANCY, body),
  updateVacancy: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_VACANCY, body),
  getAllVacancy: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_VACANCY, body),
  getVacancyByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_VACANCY_BY_UUID, body),
  deleteVacancy: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_VACANCY, params),

  createUser: (body: ParamsState) => new AxiosService().postForm(API_ENDPOINTS.CREATE_USER, body),
  updateUser: (body: ParamsState) => new AxiosService().putForm(API_ENDPOINTS.UPDATE_USER, body),
  getAllUser: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_USER, body),
  getUserByUuid: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_USER_BY_UUID, body),
  deleteUser: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_USER, params),
  getUserLookup: () => new AxiosService().get(API_ENDPOINTS.USER_LOOKUP),
  updateUserFlag: (body: ParamsState) => new AxiosService().put(API_ENDPOINTS.UPDATE_USER_FLAG, body),

  createExpense: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CREATE_EXPENSE, body),
  getAllExpense: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_EXPENSE, body),
  deleteExpense: (params: ParamsState) => new AxiosService().delete(API_ENDPOINTS.DELETE_EXPENSE, params),

  generateReport: (params: ParamsState) => new AxiosService().get(API_ENDPOINTS.GENERATE_REPORT, params),

  enableTrialSubscription: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.ENABLE_TRIAL_SUBSCRIPTION, body),
  createSubscription: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.CREATE_SUBSCRIPTION, body),
  getAllSubscription: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.LIST_SUBSCRIPTION, body),
  cancelSubscription: (params: ParamsState) => new AxiosService().post(API_ENDPOINTS.CANCEL_SUBSCRIPTION, params),
  getSubscription: (body: ParamsState) => new AxiosService().get(API_ENDPOINTS.GET_SUBSCRIPTION, body),
  getSubscriptionPlan: () => new AxiosService().get(API_ENDPOINTS.GET_SUBSCRIPTION_PLAN),
  getSubscriptionPaymentMethod: () => new AxiosService().get(API_ENDPOINTS.GET_SUBSCRIPTION_PAYMENT_METHOD),
  getBilling: () => new AxiosService().get(API_ENDPOINTS.GET_BILLING),
  saveBilling: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.SAVE_BILLING, body),
  completePayment: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.COMPLETE_PAYMENT, body),
  getProfile: () => new AxiosService().get(API_ENDPOINTS.PROFILE),
  assignPermission: (body: ParamsState) => new AxiosService().post(API_ENDPOINTS.ASSIGN_PERMISSION, body),

  logout: (): AxiosPromise => axios.post(API_ENDPOINTS.LOGOUT),

}