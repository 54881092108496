import * as React from 'react';
import { CustomDrawer } from 'components';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Grid } from '@mui/material';
import { CustomCheckBox, CustomDatePicker, CustomFileUpload, CustomTextArea, CustomTextBox } from 'components/forms';
import { services } from 'redux/services';
import { useToastMessage } from 'hooks';
import { FileAcceptData, fileValidate } from 'utils/constants';

interface Props {
    open: boolean;
    onClose: () => void;
    defaultData?: any;
    refreshData?: any
}

const VisaCheckForm = ({ onClose, open, defaultData, refreshData }: Props) => {
    const { handleSubmit, formState: { errors }, control, watch, setValue, clearErrors, register, reset } = useForm({ mode: 'all' });
    const { formatMessage } = useIntl();
    const [loading, setLoading] = React.useState(false);
    const { toastMessage } = useToastMessage();
    const [fileImage, setFileImage] = React.useState({
        file: '',
    })
    function onSubmit(data: any) {
        setLoading(true);
        data.id = defaultData?.id;
        data.file = data?.file?.[0];
        data.has_departure_confirm = data?.has_departure_confirm ? 1 : 0;
        data.has_labour_clearance = data?.has_labour_clearance ? 1 : 0;
        data.has_ticket_confirm = data?.has_ticket_confirm ? 1 : 0;
        data.is_verified = data?.is_verified ? 1 : 0;
        services.updateCandidateVisaInfo(data)
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: 'updated' }, { title: "visa" }));
                onClose();
                refreshData();
            })
            .catch((err: any) => {
                setLoading(false);
                toastMessage(err?.data?.message || "Something wrong !", 'error');
            })
    }

    React.useEffect(() => {
        if (defaultData) {
            reset({
                id: defaultData.id,
                has_departure_confirm: defaultData.has_departure_confirm,
                has_labour_clearance: defaultData.has_labour_clearance,
                has_ticket_confirm: defaultData.has_ticket_confirm,
                is_verified: defaultData.is_verified,
                remarks: defaultData.remarks,
                file: null
            });
            setFileImage({ file: defaultData.ticket_image })
        }
    }, [
        defaultData,
    ]);
    
    return (
        <CustomDrawer
            formMode={'view'}
            open={open}
            onCancel={onClose}
            title={formatMessage({ id: "visa-verification" })}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CustomCheckBox
                        name="has_departure_confirm"
                        label={formatMessage({ id: "has-departure-confirm" })}
                        control={control}
                    />
                </Grid>
                {watch('has_departure_confirm') == 1 &&
                    <Grid item xs={12}>
                        <CustomDatePicker
                            control={control}
                            label={formatMessage({ id: "departure-date" })}
                            name="departure_date"
                            error={errors.departure_date}
                            rules={{ required: true }}
                            minDate={new Date()}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <CustomCheckBox
                        name="has_labour_clearance"
                        label={formatMessage({ id: "has-labour-clearance" })}
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomCheckBox
                        name="has_ticket_confirm"
                        label={formatMessage({ id: "has-ticket-confirm" })}
                        control={control}
                    />
                </Grid>
                {watch('has_ticket_confirm') == 1 &&
                    <>
                        <Grid item xs={12}>
                            <CustomDatePicker
                                control={control}
                                label={formatMessage({ id: "ticket-date" })}
                                name="ticket_date"
                                error={errors.ticket_date}
                                rules={{ required: true }}
                                minDate={new Date()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextBox
                                label={formatMessage({ id: "ticket-number" })}
                                name="ticket_number"
                                control={control}
                                error={errors?.ticket_number}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomFileUpload
                                accept={FileAcceptData.IMAGES}
                                formLabel="Ticket Image"
                                defaultFileUrl={fileImage.file}
                                error={errors?.file}
                                {...register('file', {
                                    validate: (value: any) => value?.length > 0 ? fileValidate(value?.[0]) : true,
                                })}
                                onClear={() => {
                                    setValue(`file`, null);
                                    clearErrors(`file`);
                                    reset();
                                }}
                                onChange={(file: any) => {
                                    setValue('file', file);
                                }}
                            />
                        </Grid>
                    </>
                }
                <Grid item xs={12}>
                    <CustomCheckBox
                        name="is_verified"
                        label={formatMessage({ id: "verified" })}
                        control={control}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CustomTextArea
                        formLabel={formatMessage({ id: "remarks" })}
                        name="remarks"
                        control={control}
                        minRows={6}
                    />
                </Grid>
            </Grid>
        </CustomDrawer>
    );
}

export default VisaCheckForm;