import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Alert, AlertTitle, Box, Button, Card, CardActions, CardContent, CardHeader, Container, Grid, Paper, Tab, Typography } from '@mui/material';
import { ConfirmationButton, CustomDialog, HoverLoader } from 'components';
import { useToastMessage } from 'hooks';
import { services } from 'redux/services';
import PaymentModal from './PaymentMethod';
import { Cancel, Discount, OnDeviceTraining, Payment, ShoppingBag, Star } from '@mui/icons-material';
import { groupBy } from 'lodash';
import { TabContext, TabList } from '@mui/lab';
import { useSelector } from 'react-redux';
import { RootReducerState } from 'utils/types';

const Form: React.FC = () => {
    const { toastMessage } = useToastMessage();
    const { formatMessage } = useIntl();
    const [planData, setPlanData] = useState<any>({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [cancelPlan, setCancelPlan] = useState(false);
    const [paymentOpen, setPaymentOpen] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [planId, setPlanId] = useState(null);
    const [activeTab, setActiveTab] = useState('year');
    const [hasBilledPlan, setHasBilledPlan] = useState<boolean>(false);
    const profileData = useSelector(({ auth }: RootReducerState) => auth.data);

    useEffect(() => {
        onApiCall();
    }, [])

    function onApiCall() {
        setLoading(true);
        services.getSubscriptionPlan()
            .then((res: any) => {
                const response = res.result;
                setPlanData(groupBy(response, 'period_type'));
                setHasBilledPlan(response.some((plan: any) => plan?.billing_info))
            })
            .catch((err) => {
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            }).finally(() => setLoading(false));
    }

    const handleSubmit = () => {
        setLoading(true);
        services.createSubscription({ plan_id: planId })
            .then((res: any) => {
                setLoading(false);
                onApiCall();
                setPaymentOpen(true);
                toastMessage(formatMessage({ id: 'created-message' }, { title: formatMessage({ id: 'subscription' }) }));
            })
            .catch((err) => {
                setLoading(false);
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            });
    }

    const cancelSubscription = () => {
        setLoading(true);
        services.cancelSubscription({ remarks: 'cancelled' })
            .then((res: any) => {
                setLoading(false);
                setCancelPlan(false);
                onApiCall();
                toastMessage(formatMessage({ id: 'cancelled-message' }, { title: formatMessage({ id: 'subscription' }) }));
            })
            .catch((err) => {
                setLoading(false);
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            });
    }

    const handleClose = () => {
        setOpen(false);
    };

    const onAgree = () => {
        handleSubmit();
        handleClose();
    }

    const handleEnableTrail = () => {
        setLoading(true);
        services.enableTrialSubscription({})
            .then((res: any) => {
                setLoading(false);
                toastMessage(formatMessage({ id: 'created-message' }, { title: formatMessage({ id: 'subscription-trial' }) }));
                window.location.reload();
            })
            .catch((err) => {
                setLoading(false);
                toastMessage(err?.data?.message || formatMessage({ id: "opps-error-message" }), 'error');
            });
    }

    return (
        <Container maxWidth="xl">
            <TabContext value={activeTab}>
                {loading && <HoverLoader />}
                <Grid container spacing={3}>
                    <Grid item xs={12}>

                        {/* <Typography component="div" variant="subtitle1" align="center" sx={{
                            color: 'secondary.main'
                        }} gutterBottom>
                            Pricing
                        </Typography> */}
                        <Typography variant="h3" align="center" color="textPrimary" component="p">
                            Start using our products for management!
                        </Typography>
                        {/* <Typography variant="body1" align="center" color="textSecondary" component="p">
                            Switch to a commercial plan to access advanced features & technical support.
                        </Typography> */}
                        <Alert severity="warning" variant='standard' sx={{ marginTop: 2 }}>
                            <AlertTitle>Notes</AlertTitle>
                            <Typography variant='body1'> All subscription prices are exclusive of VAT (13%).</Typography>
                        </Alert>
                    </Grid>
                    {/* {Object.keys(planData)?.length > 0 &&
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={profileData?.has_trial_eligibilty ? 10 :12}>
                                    <TabList onChange={(_, value) => setActiveTab(value)} component={Paper}>
                                        {Object.keys(planData)?.map((key: string) => <Tab label={key?.toUpperCase()} value={key} key={key} />
                                        )}
                                    </TabList>
                                </Grid>
                                {profileData?.has_trial_eligibilty &&
                                <Grid item xs={2}>
                                    <ConfirmationButton
                                        startIcon={<OnDeviceTraining />}
                                        variant='contained'
                                        color='success'
                                        label='Enable trail'
                                        fullWidth
                                        size='large'
                                        title="Enable trial period"
                                        subTitle="The trail period works only for 7 days."
                                        onConfirm={handleEnableTrail}
                                    />
                                </Grid>
                                }
                                {planData?.[activeTab]?.map((item: any, i: number) => (
                                    <Grid item key={i} xs={12} sm={6} md={3}>
                                        <Card sx={{ height: '100%', display: "flex", flexDirection: "column" }}>
                                            <CardHeader
                                                title={item.name}
                                                subheader={item?.subheader}
                                                titleTypographyProps={{ align: 'center' }}
                                                subheaderTypographyProps={{ align: 'center' }}
                                                action={item?.name === 'Pro' ? <Star /> : null}
                                                sx={{
                                                    bgcolor: 'secondary.lighter',
                                                }}
                                            />
                                            <CardContent style={{ margin: "auto" }}>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'baseline',
                                                }}>
                                                    <Typography component="h2" variant="h3" color="textPrimary">
                                                        Rs {item.total_amount}
                                                    </Typography>
                                                    <Typography variant="h6" color="textSecondary">
                                                        /{item.period_type}
                                                    </Typography>
                                                </Box>
                                                <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'baseline',
                                                    marginBottom: 2,

                                                }}>
                                                    <Typography component="h6" variant="h6" color="textSecondary" sx={{ textDecoration: "line-through" }} >
                                                        Rs {Number(item.total_amount) + Number(item.saved_amount)}
                                                    </Typography>
                                                    &nbsp;&nbsp;
                                                    <Typography variant="caption" color="textSecondary">
                                                        Rs {item.saved_amount} <Discount fontSize='small' />
                                                    </Typography>

                                                </Box>

                                                <ul style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    listStyle: 'none',
                                                }}>
                                                    {item?.features?.map((line: any, index: any) => (
                                                        <Typography component="li" variant="body2" align="center" key={index}>
                                                            {line}
                                                        </Typography>
                                                    ))}
                                                </ul>
                                            </CardContent>
                                            <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                                                {item.billing_info ?
                                                    <>
                                                        <Button
                                                            startIcon={<Payment />}
                                                            color='info'
                                                            // fullWidth 
                                                            variant={'contained'}
                                                            onClick={() => {
                                                                setPaymentOpen(true);
                                                                setTotalAmount(item.total_amount)
                                                            }}
                                                        >
                                                            Pay Now
                                                        </Button>
                                                        <Button
                                                            startIcon={<Cancel />}
                                                            color='error'
                                                            // fullWidth 
                                                            variant={'contained'}
                                                            onClick={() => {
                                                                setCancelPlan(true);
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </>
                                                    :
                                                    <Button
                                                        startIcon={<ShoppingBag />}
                                                        variant={'contained'}
                                                        onClick={() => {
                                                            setOpen(true);
                                                            setPlanId(item.id);
                                                            setTotalAmount(item.total_amount)
                                                        }}
                                                        disabled={profileData?.is_trial == 1 ? false : (hasBilledPlan || profileData?.has_subscribed && !profileData?.has_expired) ? true : false}
                                                    >
                                                        Buy Now
                                                    </Button>
                                                }
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    } */}

                    {planData?.[activeTab]?.map((item: any, i: number) => (
                        <Grid item key={i} xs={12} sm={6} md={3}>
                            <Card sx={{ height: '100%', display: "flex", flexDirection: "column" }}>
                                <CardHeader
                                    title={item.name}
                                    subheader={item?.subheader}
                                    titleTypographyProps={{ align: 'center' }}
                                    subheaderTypographyProps={{ align: 'center' }}
                                    action={item?.name === 'Pro' ? <Star /> : null}
                                    sx={{
                                        bgcolor: 'secondary.lighter',
                                    }}
                                />
                                <CardContent style={{ margin: "auto" }}>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                    }}>
                                        <Typography component="h2" variant="h3" color="textPrimary">
                                            Rs {item.total_amount}
                                        </Typography>
                                        <Typography variant="h6" color="textSecondary">
                                            /{item.period_type}
                                        </Typography>
                                    </Box>
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'baseline',
                                        marginBottom: 2,

                                    }}>
                                        <Typography component="h6" variant="h6" color="textSecondary" sx={{ textDecoration: "line-through" }} >
                                            Rs {Number(item.total_amount) + Number(item.saved_amount)}
                                        </Typography>
                                        &nbsp;&nbsp;
                                        <Typography variant="caption" color="textSecondary">
                                            Rs {item.saved_amount} <Discount fontSize='small' />
                                        </Typography>

                                    </Box>

                                    <ul style={{
                                        margin: 0,
                                        padding: 0,
                                        listStyle: 'none',
                                    }}>
                                        {item?.features?.map((line: any, index: any) => (
                                            <Typography component="li" variant="body2" align="center" key={index}>
                                                {line}
                                            </Typography>
                                        ))}
                                    </ul>
                                </CardContent>
                                <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                                    {item.billing_info ?
                                        <>
                                            <Button
                                                startIcon={<Payment />}
                                                color='info'
                                                // fullWidth 
                                                variant={'contained'}
                                                onClick={() => {
                                                    setPaymentOpen(true);
                                                    setTotalAmount(item.total_amount)
                                                }}
                                            >
                                                Pay Now
                                            </Button>
                                            <Button
                                                startIcon={<Cancel />}
                                                color='error'
                                                // fullWidth 
                                                variant={'contained'}
                                                onClick={() => {
                                                    setCancelPlan(true);
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </>
                                        :
                                        <Button
                                            startIcon={<ShoppingBag />}
                                            variant={'contained'}
                                            onClick={() => {
                                                setOpen(true);
                                                setPlanId(item.id);
                                                setTotalAmount(item.total_amount)
                                            }}
                                            disabled={profileData?.is_trial == 1 ? false : (hasBilledPlan || profileData?.has_subscribed && !profileData?.has_expired) ? true : false}
                                        >
                                            Buy Now
                                        </Button>
                                    }
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                {paymentOpen && <PaymentModal
                    open={paymentOpen}
                    handleClose={() => setPaymentOpen(false)}
                />
                }

                {open && (
                    <CustomDialog
                        open={open}
                        onDisagree={handleClose}
                        onAgree={onAgree}
                        title="Are you sure you want to purchase this plan?"
                        subTitle="Once purchased, you cannot change the plan until the subscription expires."
                    />
                )}

                {cancelPlan && (
                    <CustomDialog
                        open={cancelPlan}
                        onDisagree={() => setCancelPlan(false)}
                        onAgree={cancelSubscription}
                        title="Are you sure you want to cancel this plan?"
                        subTitle="Once you cancel, you can choose other suitable the plan"
                    />
                )}
            </TabContext>
        </Container>
    )
}

export default Form;